<template>
  <div id="IOTPlatform">
    <!-- IOTPlatformHeader首页头部 -->
    <div class="IOTPlatformHeader">
      <!-- 头部组件 -->
      <Header></Header>
      <div class="texts">
        <div class="text1">IOT平台</div>
        <div class="text2"></div>
        <div class="text3">
         IoT平台提供海量设备的接入和管理，支持终端设备直接接入，也可以通过工业网关接入；支持多网络接入、多协议接入、多Agent接入，解决设备接入复杂多样化和碎片化难题；提供基础的设备管理功能，实现设备的快速接入。
        </div>
      </div>
    </div>
    <!-- IOTCenter中间内容 -->
    <div class="IOTCenter">
      <!-- 方案构架内容 -->
      <div class="schemeConstruct">
        <div class="schemeConTit">方案架构</div>
        <div class="schemeConText">实现数据规律的可视、可控、可优</div>
        <div class="schemeContent">
          <div class="schemeContentleft">
            <img src="~assets/img/IOTPlatform/方案架构@2x.png" alt="" />
          </div>
          <ul class="schemeContentRight">
            <li v-for="(item, index) in schemeContentRight" :key="index">
              <div class="schemeConRiTit">
                <img
                  src="~assets/img/IOTPlatform/icon-勾@2x.png"
                  alt=""
                  class="bin_go"
                />{{ item.title }}
              </div>
              <div class="schemeConRiText">
                {{ item.Text }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <!-- 数字工业引擎 -->
      <div class="digitalIndustEn">
        <div class="digitalIndustEnTit">数字工业引擎</div>
        <div class="digitalIndustEnText">
          架设数字制造的基础设施，为制造企业提供基于IOT平台的物联网、云计算和工业大数据的支持，帮助更多企业实现工厂的数字化、产业链的打通和协同，以及产品本身的智能化升级。
        </div>
        <ul class="digitalIndustEnContent">
          <li v-for="(item, index) in digitalIndustEnContent" :key="index">
            <div class="digitalIndustEnConicon">
              <img :src="item.Icons" alt="" />
              <div class="digitalIndustEnConPro">{{ item.iconsTit }}</div>
            </div>
            <div class="digitalIndustEnConText">
              {{ item.iconsPro }}
            </div>
          </li>
        </ul>
      </div>
      <!-- 产品特性 -->
      <div class="proFeature">
        <div class="proFeatureTit">产品特性</div>
        <div class="proFeatureContent">
          <img
            v-if="currentIndex === 0"
            src="~assets/img/IOTPlatform/采集器.png"
            alt="采集器"
          />
          <img
            v-if="currentIndex === 1"
            src="~assets/img/IOTPlatform/边缘计算@2x.png"
            alt="边缘计算"
          />
          <img class="miniappImg"
            v-if="currentIndex === 2"
            src="~assets/img/IOTPlatform/共享数据@2x.png"
            alt="共享数据"
          />
          <div class="proFeatureConCenter">
            <img
              v-if="currentIndex === 0"
              src="~assets/img/IOTPlatform/icon-链接万物@2x.png"
              alt="链接万物"
            />
            <img
              v-if="currentIndex === 1"
              src="~assets/img/IOTPlatform/icon-边缘计算@2x.png"
              alt="多数据来源支持"
            />
            <img
              v-if="currentIndex === 2"
              src="~assets/img/IOTPlatform/icon-共享数据@2x.png"
              alt="图标类型"
            />
            <div v-if="currentIndex === 0" class="proFeaCenterTit">
              传感器实时采集生产数据
            </div>
            <div v-if="currentIndex === 1" class="proFeaCenterTit">
              采集器实时存储数据
            </div>
            <div v-if="currentIndex === 2" class="proFeaCenterTit">
              随时随地掌控业务动态
            </div>
            <div v-if="currentIndex === 0" class="proFeaCenterContent">
              ·通过传感器，全天候实时采集每台设备的生产数据，掌握生产动态。<br />
              ·实现工厂设备数据采集全覆盖、零遗漏。
            </div>
            <div v-if="currentIndex === 1" class="proFeaCenterContent">
              ·采集数据存储计算集成在采集端，轻松应对断电断网等突发情况。<br />
              ·为数据安全保驾护航。
            </div>
            <div v-if="currentIndex === 2" class="proFeaCenterContent">
              ·通过小程序、平台等传递给生产管理人员生产数据信息。<br />
              ·管理人员通过采集到的数据了解生产情况，且可以作出及时调整。
            </div>
          </div>
          <div class="proFeatureRight">
            <ul class="proFeaRightContent">
              <li
                v-for="(item, index) in proFeaRightContent"
                :key="index"
                :class="currentIndex === index ? 'change' : ''"
                @click="changeCon(index)"
              >
                {{ item.texts }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <!-- 回到顶部 -->
    <el-backtop
      ><img
        src="~assets/img/AboutUs/icon-一键置顶@2x.png"
        alt="icon-回到顶部@2x.png"
    /></el-backtop>
  </div>
</template>
<script>
import Header from "components/Header/dsHeader";
import Footer from "components/Footer/dsFooter";
export default {
  name: "IOTPlatform",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      schemeContentRight: [
        {
          title: "行业&区域平台",
          Text: "平台化运营，促进产业数字化及工业互联网产业的发展。"
        },
        {
          title: "企业级平台—数字工厂",
          Text: "助力企业实现全面的数字化升级，提升生产运营能力。"
        },
        {
          title: "工业物联网平台",
          Text:
            "专业化的设备、应用数据上云服务，提供安全、可靠、高效的联网平台。"
        }
      ],
      digitalIndustEnContent: [
        {
          Icons: require("assets/img/IOTPlatform/icon-报警-1@2x.png"),
          iconsTit: "告警消息服务",
          iconsPro:
            "提供故障和报警消息通知到指定员工、组织或者角色，用户可以在数字工厂接收消息和报警提醒，也可以在数字工厂中对指定对象发送消息通知。"
        },
        {
          Icons: require("assets/img/IOTPlatform/icon-账号管理服务@2x.png"),
          iconsTit: "账号管理服务",
          iconsPro:
            "通过服务为工业应用提供统一基于工业互联网平台的登录账号，定义和授予用户、组织和角色权限管理。"
        },
        {
          Icons: require("assets/img/IOTPlatform/icon-生产追溯服务@2x.png"),
          iconsTit: "生产追溯服务",
          iconsPro:
            "对生产过程中的成品和半成品的质检结果进行管理，一旦出现异常，能够即时发现并解决问题，能对异常原因扰动的出现起预警作用。"
        },
        {
          Icons: require("assets/img/IOTPlatform/icon-数据分析服务.png"),
          iconsTit: "数据分析服务",
          iconsPro:
            "通过采集到的数据，如设备运行状态、生产效率、成品入据、物料出库及库存数据、人员信息等，对数据进行分析，生成各个业务域的经营分析方法。"
        },
        {
          Icons: require("assets/img/IOTPlatform/icon-设备综合效率分析@2x.png"),
          iconsTit: "设备综合效率分析",
          iconsPro:
            "通过采集的生产设备的工作时间、产量、质量和故障等信息，提供实时计算设备综合效率（OEE）的结果，帮助企业对设备的效率进行分析和优化。"
        },
        {
          Icons: require("assets/img/IOTPlatform/icon-工厂日历服务@2x.png"),
          iconsTit: "工厂日历服务",
          iconsPro:
            "为工厂计划员实现预期目标，通过调度工厂班次和安排停产停工时间为后续生产进度安排、生产效率分析的提供基础服务。"
        }
      ],
      proFeaRightContent: [
        { texts: "泛在连接" },
        { texts: "边缘计算" },
        { texts: "共享数据" }
      ],
      currentIndex: 0
    };
  },
    mounted() {  
      document.title = '岱山汽船配工业互联网平台';   
  },
  methods: {
    changeCon(n) {
      this.currentIndex = n;
      console.log(this.currentIndex);
    }
  }
};
</script>
<style lang="less">
#IOTPlatform {
 width: 1583px;
  background-color: #f8f9fb;
  margin: 0 auto;
  // IOTPlatformHeader头部样式
  .IOTPlatformHeader {
     width: 1583px;
    height: 620px;
    background: url("~assets/img/IOTPlatform/banner@2x.png") no-repeat;
    background-size: 1583px 620px;
    background-position: center;
    margin: 0 auto;
    // margin: 0 auto;
    .texts {
      margin-left: 900px;
      .text1 {
        // width: 221px;
        height: 38px;
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        line-height: 25px;
        color: #1e90ff;
        opacity: 1;
        margin-top: 151px;
      }
      .text2 {
        width: 100px;
        height: 10px;
        background: #1e90ff;
        opacity: 1;
        border-radius: 5px;
        margin-top: 11px;
      }
      .text3 {
        width: 489px;
        height: 143px;
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 36px;
        color: rgba(51, 51, 51, 0.65);
        opacity: 1;
        margin-top: 20px;
      }
    }
  }
  // IOTPlatformHeader中间内容样式
  .IOTCenter {
    margin: 80px auto 101px;
    // 方案构架样式
    .schemeConstruct {
      text-align: center;
      .schemeConTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
      }
      .schemeConText {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 28px;
      }
      .schemeContent {
        width: 1220px;
        height: 428px;
        margin: 30px auto 0;
        display: flex;
        justify-content: flex-start;
        .schemeContentleft {
          img {
            width: 823px;
            height: 428px;
          }
        }
        .schemeContentRight {
          width: 397px;
          height: 428px;
          margin: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 81px;

          li {
            display: flex;
            flex-direction: column;
            justify-content: center;

            margin-bottom: 40px;
            .schemeConRiTit {
              text-align: left;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #313333;

              img {
                width: 20px;
                height: 20px;
                vertical-align: middle;
                margin-right: 8px;
              }
            }
            .schemeConRiText {
              width: 289px;
              height: 42px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 28px;
              color: rgba(51, 51, 51, 0.65);
              text-align: left;
              margin-top: 12px;
              margin-left: 28px;
            }
          }
        }
      }
    }
    // 数字工业引擎
    .digitalIndustEn {
      margin-top: 80px;
      text-align: center;
      .digitalIndustEnTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
      }
      .digitalIndustEnText {
        width: 880px;
        height: 46px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 28px;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
        margin: 28px auto 0;
      }
      .digitalIndustEnContent {
        width: 1220px;
        height: 462px;
        margin: 30px auto 0;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: space-between;
        li {
          width: 340px;
          height: 201px;
          background-color: #fff;

          .digitalIndustEnConicon {
            display: flex;
            margin-left: 34px;
            img {
              width: 31px;
              height: 30px;
              margin-top: 24px;
            }
            .digitalIndustEnConPro {
              width: 125px;
              height: 38px;
              background: #e7f3fe;
              opacity: 1;
              border-radius: 6px;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              line-height: 38px;
              color: #313333;
              margin: 21px 34px 0 116px;
            }
          }
          .digitalIndustEnConText {
            width: 280px;
            height: 103px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 28px;
            color: rgba(51, 51, 51, 0.85);
            text-align: left;
            margin: 19px 30px 20px;
          }
        }
      }
    }
    // 产品特性
    .proFeature {
      margin-top: 80px;
      .proFeatureTit {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: rgba(51, 51, 51, 0.85);
        text-align: center;
      }
      .proFeatureContent {
        position: relative;
        width: 1220px;
        height: 300px;
        margin: 30px auto;
        background: #fff;
        display: flex;

        img {
          width: 400px;
          height: 240px;
          margin: 30px 0 30px 30px;
        }
        .miniappImg{
          width: 300px;
          height: 252px;
          margin: 28px 0 20px 80px;
        }
        .proFeatureConCenter {
          margin-left: 60px;
          img {
            width: 36px;
            height: 36px;
            margin: 50px 0 0px;
          }
          .proFeaCenterTit {
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: rgba(51, 51, 51, 0.85);
            margin-top: 20px;
          }
          .proFeaCenterContent {
            width: 432px;
            height: 40px;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 26px;
            color: rgba(51, 51, 51, 0.65);
            margin-top: 30px;
          }
        }
        .proFeatureRight {
          position: absolute;
          width: 212px;
          height: 300px;
          background: #e7f3fe;
          opacity: 1;
          border-radius: 10px;
          // margin-left: 86px;
          right: 0;

          .proFeaRightContent {
            text-align: center;
            padding: 0;
            li {
              margin: 50px auto;
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: rgba(51, 51, 51, 0.85);
              cursor: pointer;

              /*   &:hover {
                color: #1e90ff;
                font-weight: 500;
              } */
            }
            .change {
              color: #1e90ff;
              font-weight: 500;
              border-right: 5px solid #1e90ff;
            }
          }
        }
      }
    }
  }
}
.el-backtop {
  img {
    width: 30px;
    height: 30px;
  }
}
</style>
